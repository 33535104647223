/* eslint-disable mui-unused-classes/unused-classes */
import { makeStyles } from "@material-ui/core";

export const useMicroStyles = makeStyles((theme) => ({
  flexSection: {
    display: "flex",
    marginBottom: theme.spacing(1.5),
  },
  label: {
    ...theme.typography.body1,
    whiteSpace: "nowrap",
    paddingBottom: 2,
    color: theme.colors.black,
    paddingTop: theme.spacing(0.75),
    width: 60,
  },
  control: {},
  inputRoot: {
    color: theme.colors.primary,
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: 1,
  },
  input: {
    paddingTop: 3,
    lineHeight: theme.typography.body1.fontSize,
    height: theme.typography.body1.fontSize,
    "&:focus": {
      "&::placeholder": {
        opacity: 0.6,
      },
    },
    "&::placeholder": {
      color: theme.colors.grey,
      opacity: 1,
    },
  },
  submitBtn: {
    position: "absolute",
    bottom: theme.spacing(1.5),
    right: theme.spacing(1.5),
  },
}));

export const useCalendarStyles = makeStyles((theme) => ({
  micro: {
    "& .MuiPickersCalendarHeader-dayLabel": {
      width: 30,
      margin: 0,
    },
    "& .MuiPickersDay-day": {
      width: 30,
      height: 30,
      margin: 0,
    },
    "& .MuiPickersCalendarHeader-switchHeader": {
      margin: 0,
    },
    "& .MuiPickersCalendarHeader-iconButton": {
      padding: theme.spacing(1, 0),
    },
    "& .MuiPickersCalendar-transitionContainer": {
      margin: 0,
      maxHeight: 180,
      minHeight: 180,
    },
    "& .MuiPickersStaticWrapper-staticWrapperRoot": {
      maxWidth: 210,
      minWidth: 180,
    },
    "& .MuiPickersBasePicker-pickerView": {
      maxWidth: 210,
      minWidth: 180,
      minHeight: 250,
    },
  },
}));
