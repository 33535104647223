import { Box, TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from "@material-ui/core";
import { parseDate, ParsingOption as ChronoParsingOption } from "chrono-node";
import { format as formatFn } from "date-fns";
import { ChangeEvent, FocusEvent, useCallback, useMemo, useRef } from "react";
import { FieldValues } from "react-hook-form";
import { IRhfControl } from ".";
import { DATE_DISPLAY_FORMAT, isDateNowOrEarlier, isDateToday } from "../../../utils/dates";
import { deepmerge } from "../../../utils/objects";
import { DateTimePicker } from "../DateTimePicker";
import { RhfControlled, RhfControlledProps, RhfControlledRenderProps } from "./RhfControlled";

const parse = (value: string, refDate?: Date, options?: ChronoParsingOption): Date | undefined => {
  if (!value) return undefined;
  return parseDate(value, refDate, { ...options });
};

export type DateControlRenderProps = MuiTextFieldProps & RhfControlledRenderProps & {};

export type DateControlProps = Omit<MuiTextFieldProps, "required"> &
  Omit<RhfControlledProps<FieldValues, DateControlRenderProps>, "render"> & {
    format?: string;
    formatOnBlur?: boolean;
    refDate?: Date;
    chronoOptions?: ChronoParsingOption;
    required?: string | boolean;
    defaultDatePickerValue?: string;
    defaultDatePickerHour?: number;
    containerClassName?: string;
    disablePast?: boolean;
    disableFuture?: boolean;
    inlinePicker?: boolean;
    dayMode?: boolean;
  };

export const DateControl: IRhfControl<DateControlProps> = ({
  rules,
  format = DATE_DISPLAY_FORMAT,
  formatOnBlur = true,
  refDate,
  chronoOptions,
  required,
  helperText,
  defaultDatePickerValue,
  defaultDatePickerHour,
  containerClassName,
  disablePast,
  disableFuture,
  inlinePicker,
  dayMode,
  ...rest
}) => {
  const anchorElRef = useRef<HTMLInputElement | undefined>();

  const merged: DateControlProps["rules"] = useMemo(
    () =>
      deepmerge({}, rules, {
        required,
        validate: {
          parseable: (v: string) => !v || !!parse(v, refDate, chronoOptions) || "invalid date",
        },
      }),
    [chronoOptions, refDate, rules, required]
  );

  const render = useCallback(
    ({ field, fieldState, formState, ...rest }: DateControlRenderProps) => {
      const { name, value, ref: inputRef, onChange, onBlur } = field;

      const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e);
        rest.onChange?.(e);
      };

      const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        const parsed = parse(e.target.value, refDate, chronoOptions);

        // input is a valid date
        if (!!parsed && formatOnBlur) {
          if (!!dayMode) {
            onChange(isDateToday(parsed) ? "Today" : formatFn(parsed, DATE_DISPLAY_FORMAT));
          } else if (isDateNowOrEarlier(parsed)) {
            onChange("now");
          } else {
            onChange(formatFn(parsed, format));
          }
        }

        onBlur();
        rest.onBlur?.(e);
      };

      const handlePickerChange = (name: string, date: Date) => {
        if (defaultDatePickerHour) {
          date.setHours(defaultDatePickerHour);
          date.setMinutes(0);
          date.setSeconds(0);
        }
        let pickerValue;

        if (!dayMode) {
          pickerValue = isDateNowOrEarlier(date) ? "now" : formatFn(date, format);
        } else {
          pickerValue = isDateToday(date) ? "Today" : formatFn(date, DATE_DISPLAY_FORMAT);
        }

        /**
         * The date picker selection is triggered after the native input events are triggered. This causes
         * validation timing issues with the new input value. The following synthetically triggers the value
         * being set in the input (triggers handleChange above). Blur is called before rhf knows of the change so we
         * need the blur event to trigger again for rhf to have the correct value before triggering blur logic.
         */
        const valueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set;
        valueSetter?.call(anchorElRef.current, pickerValue);

        const syntheticChange = new Event("input", { bubbles: true });
        anchorElRef.current?.dispatchEvent(syntheticChange);

        anchorElRef.current?.focus();
        setTimeout(() => anchorElRef.current?.blur(), 10);
      };

      const datePickerValue = parse(
        !value && defaultDatePickerValue ? defaultDatePickerValue : value,
        refDate,
        chronoOptions
      );

      return (
        <Box className={containerClassName}>
          <MuiTextField
            {...rest}
            inputRef={(el) => {
              // Set the anchor ref and then trigger the inputRef
              // callback. RHF needs this el and the DateTimePicker.
              if (!anchorElRef.current) {
                anchorElRef.current = el;
              }

              inputRef(anchorElRef.current);
            }}
            {...{ name, value, onChange: handleChange, onBlur: handleBlur }}
            error={!!fieldState.error}
            helperText={!!fieldState.error?.message ? fieldState.error?.message : helperText}
          />

          {/* TODO: (SS) Likely will need an option to not show the date picker in mobile? */}
          <DateTimePicker
            anchorEl={anchorElRef}
            value={datePickerValue}
            onChange={handlePickerChange}
            inlinePicker={inlinePicker}
            disablePast={disablePast}
            disableFuture={disableFuture}
          />
        </Box>
      );
    },
    [
      chronoOptions,
      containerClassName,
      format,
      refDate,
      helperText,
      formatOnBlur,
      defaultDatePickerValue,
      defaultDatePickerHour,
      inlinePicker,
      disablePast,
      disableFuture,
      dayMode,
    ]
  );

  return <RhfControlled {...rest} rules={merged} render={render} />;
};

DateControl.isControl = true;
DateControl.isController = true;
